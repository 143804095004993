import { faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { AnimatePresence, cubicBezier, motion } from 'framer-motion';
import Link from 'next/link';
import type { FC } from 'react';

import { isDevelopment } from '@/config/config';
import { useProfile } from '@/hooks/useProfile';

import Button from '../Button';
import Text from '../Text';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

const Hamburger: FC<Props> = ({ onClose, isOpen }) => {
  const { data } = useProfile();
  const getPages = () => {
    if (isDevelopment)
      return [
        { name: 'HOME', href: '/' },
        { name: 'PRICING', href: '/pricing' },
        { name: 'DASHBOARD', href: '/' },
        { name: 'FAQ', href: '/' },
        { name: 'CONTACT', href: '/' },
      ];
    if (data)
      return [
        { name: 'HOME', href: '/' },
        { name: 'PRICING', href: '/pricing' },
        { name: 'JOB MATCHING', href: 'https://app.mindpal.co/job-matching' },
      ];
    return [
      { name: 'HOME', href: '/' },
      { name: 'PRICING', href: '/pricing' },
    ];
  };
  const socials = [
    { icon: faLinkedinIn, href: 'https://www.linkedin.com/company/mindpal.co' },
    { icon: faInstagram, href: 'https://www.instagram.com/mindpal.co/' },
  ];
  const easing = cubicBezier(0.82, 0, 0.22, 0.99);
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          role="presentation"
          onClick={onClose}
          transition={{ ease: easing }}
          className={classNames('absolute left-0 top-0 h-full w-full z-[1]', {
            '-z-50': !isOpen,
          })}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute -z-40 h-full w-full backdrop-blur-sm"
          />
          <motion.div
            initial={{
              backgroundImage:
                'radial-gradient(circle at top right, #27125A 0%, transparent 0%)',
            }}
            animate={{
              backgroundImage:
                'radial-gradient(circle at top right, #27125A 100%, transparent 0%)',
            }}
            exit={{
              backgroundImage:
                'radial-gradient(circle at top right, #27125A 0%, transparent 0%)',
            }}
            transition={{ ease: easing, duration: 0.5 }}
            role="presentation"
            onClick={(e) => e.stopPropagation()}
            className={classNames(
              'overflow-hidden fixed right-0 top-0 flex h-3/4 w-96 flex-col justify-between rounded-bl-3xl pb-10 pl-14 pr-5 pt-5'
            )}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex flex-col"
              transition={{ ease: easing }}
            >
              <Button
                className="self-end"
                variant="unstyled"
                size={null}
                onClick={onClose}
              >
                <FontAwesomeIcon icon={faXmark} size="2x" />
              </Button>
              <div className="flex flex-col gap-5">
                {getPages().map((page, index) => (
                  <div key={page.name} className="overflow-hidden">
                    <motion.div
                      initial={{ y: 200 }}
                      animate={{ y: 0 }}
                      exit={{ y: 200 }}
                      transition={{ delay: 0.1 + index * 0.1, ease: easing }}
                    >
                      <Link href={page.href} className="group">
                        <div className="w-min">
                          <Text className="w-min !text-[2.4375rem] font-semibold leading-[100%]">
                            {page.name}
                          </Text>

                          <div className="h-[2px] w-0 bg-white transition-all duration-600 ease-custom-hamburger-link group-hover:w-full" />
                        </div>
                      </Link>
                    </motion.div>
                  </div>
                ))}
              </div>
            </motion.div>
            <motion.div
              initial={{ y: 200 }}
              animate={{ y: 0 }}
              exit={{ y: 200, opacity: 0 }}
              transition={{ ease: easing }}
              className="flex gap-2 self-end"
            >
              {socials.map(({ href, icon }) => (
                <Link key={href} href={href}>
                  <FontAwesomeIcon icon={icon} />
                </Link>
              ))}
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Hamburger;
