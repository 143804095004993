import {
  Popover,
  PopoverContent as PopoverContentBase,
  PopoverTrigger,
} from '@mindpal-co/mindpal-ui';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef } from 'react';

import { cn } from '@/lib/utils';

const PopoverContent = forwardRef<
  ElementRef<typeof PopoverContentBase>,
  ComponentPropsWithoutRef<typeof PopoverContentBase>
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) => (
  <PopoverContentBase
    ref={ref}
    align={align}
    sideOffset={sideOffset}
    className={cn('border-slate-800 bg-slate-950 text-slate-50', className)}
    {...props}
  />
));

PopoverContent.displayName = PopoverContentBase.displayName;

export { Popover, PopoverContent, PopoverTrigger };
